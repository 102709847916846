// ReSharper disable InconsistentNaming
import 'babel-polyfill';
import 'url-polyfill';
import Vue from 'vue';
import Vuetify from 'vuetify';

import '../sass/identity.scss';
import '../images/ds-logo-full.svg';
import '../images/dude.ico';

import App from './App.vue';
import store from './store';
import router from './router';

import identityApi from './api';

window.onload = function () {

    Vue.use(Vuetify,
        {
            iconfont: 'md'
        });


    Vue.prototype.$identityApi = identityApi;


    const vue = new Vue({
        el: '#app-root',

        store,
        router,

        render: h => h(App)
    })
};